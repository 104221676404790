.title {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -0.015em;

  color: #333333;
}

.mentalHealthIssueText {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.015em;

  color: #333333;
}

.mentalHealthIssueView {
  justify-content: left;
}

.missionText {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;

  color: #333333;
}

.linksStyle {
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.015em;
}

.websiteButton {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #192873;
  border-radius: 4px;

  width: 235px;
  height: 48px;

  color: #ffffff;
}

.websiteButton:hover {
  background: lightblue;
}

.emailButton {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 0px;
  gap: 10px;

  width: 235px;
  height: 48px;

  background: #ffffff;

  border: 1px solid #192873;
  border-radius: 4px;

  color: #192873;
}
