.tabStyle {
  height: 103px;
  color: #707070;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;
}
