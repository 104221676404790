.servicesFlex {
  height: 240px;
  font-family: "Inter";
  font-style: normal;
  background: #e0e5ff;
}

.servicesContainer {
  margin-bottom: 47px;
  margin-top: 47px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #333333;
}

.serviceText {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.015em;
  color: #333333;
}
